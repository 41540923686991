import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { IconContext } from "react-icons"
import { FaQuoteRight } from "react-icons/fa"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

import indexStyles from "../styles/pages/index.module.css"
import contactUsVideo from "../videos/contact-us-vid.mp4"
import posterImg from "../images/poster.png"

const IndexPage = ({ data }) => (
  <Layout location="home">
    <SEO title="Home" />
    <div className={ indexStyles.aboutSection }>
      <div className={ indexStyles.aboutImgContainer }>
        <Img fluid={data.image1.childImageSharp.fluid} />
      </div>
      <div className={ indexStyles.aboutText }>
        <div className={ indexStyles.tagline }>
          <h1>Connect, Communicate, Create</h1>
          <h3>...with your target audience</h3>
        </div>
        <p>Integrated Marketing Communications. Made easy. For you.</p>
        <Link to="/about/">Learn More</Link>
      </div>
    </div>
    <div className={ indexStyles.contactSection }>
      <h1>Communication with customers has evolved.</h1>
      <div className={ indexStyles.canPortion }>
        <h3 className={ indexStyles.topHalf }>So should you.</h3>
        <div className={ indexStyles.contactImgContainer }>
          <Img fluid={data.image2.childImageSharp.fluid} />
        </div>
        <h3 className={ indexStyles.bottomHalf }>EVOLVE OR <span>PERISH</span></h3>
      </div>
      <div className={ indexStyles.shortDesc }>
        <p>In an ever changing business environment, you have to keep your competitive edge ever sharp.</p>
        <p>You'll always need a firm to help you sell your products, programs & services through effective integrated marketing communications that move with the times, and more importantly, that move people.</p>
        <p>That's where our company comes in. We partner with you in executing your marketing communication programs, putting our resources, expertise and network to work for you.</p>
      </div>
    </div>
    <div className={ indexStyles.contactUsVideoSection }>
      <Video src={ contactUsVideo } poster={ posterImg } />
      <div className={ indexStyles.contactUsText }>
        <div className={ indexStyles.contactUsTextWrapper }>
          <h1>Work With Us.</h1>
          <p>Take your brand to the next level with our services.</p>
          <Link to="/contact/">Inquire Now</Link>
        </div>
      </div>
    </div>
    <div className={ indexStyles.quotes }>
      <div className={ indexStyles.clapIcon }><Img fluid={data.icon1.childImageSharp.fluid} /></div>
      <h3>Rave Reviews</h3>
      <div className={ indexStyles.quote1 }>
        <Img fluid={data.quote1.childImageSharp.fluid} />
      </div>
      <div className={ indexStyles.quote2 }>
        <Img fluid={data.quote2.childImageSharp.fluid} />
      </div>
      <div className={ indexStyles.quote3 }>
        <Img fluid={data.quote3.childImageSharp.fluid} />
      </div>
    </div>
  </Layout>
)

export const screenWidth = graphql`
  fragment screenWidth on File {
    childImageSharp {
      fluid(maxWidth: 2000) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const mediumSize = graphql`
  fragment mediumSize on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const quote = graphql`
  fragment quote on File {
    childImageSharp {
      fluid(maxWidth: 992) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const icon = graphql`
  fragment icon on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "landing-img.png" }) {
      ...screenWidth
    }
    image2: file(relativePath: { eq: "can.png" }) {
      ...mediumSize
    }
    quote1: file(relativePath: { eq: "Arlene P. Padua-Martinez.png" }) {
      ...quote
    }
    quote2: file(relativePath: { eq: "Cecille Pinlac.png" }) {
      ...quote
    }
    quote3: file(relativePath: { eq: "Ma. Cristina Llacer-Oreta.png" }) {
      ...quote
    }
    icon1: file(relativePath: { eq: "clap-icon.png" }) {
      ...icon
    }
  }
`

export default IndexPage
